import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import {
  getBaseUrl,
  isAlbumTemplateV8,
  shouldAllowSelections,
} from '../../common/utils';
import { ALBUMS } from '../../consts/photographyConsts';
import type AppStateManager from '../../viewerScriptHelpers/AppStateManager';

const createController: CreateControllerFn = async (props: any) => {
  const { flowAPI, controllerConfig: config, appData } = props;
  const { appStateManager } = appData as {
    appStateManager: AppStateManager;
  };
  const { environment } = flowAPI;
  const isViewer = environment.isViewer || environment.isSSR;
  const { setProps } = flowAPI.controllerConfig;
  const { isTemplate, storeManager, dataFetcher } = appData;
  const isSSR = flowAPI.environment.isSSR;
  if (flowAPI.environment.isEditor || isTemplate) {
    return {
      pageReady: () => {
        setProps({
          isEditor: true,
        });
      },
    };
  }

  const {
    wixCodeApi,
    compId,
    appParams: { instance },
  } = config;

  const baseUrl = getBaseUrl(isViewer);

  const albumData: AlbumData = await dataFetcher.getAlbumData({
    baseUrl,
    isPreview: environment,
  });

  if (isSSR) {
    return {
      pageReady: () => {
        const pushToRight =
          !isAlbumTemplateV8(albumData.settings) &&
          !shouldAllowSelections(
            albumData.settings,
            wixCodeApi.site.currentPage.name,
          );

        setProps({
          isHidden: true,
          loggedIn: appStateManager.state.user.loggedIn,
          pushToRight,
        });
      },
    };
  }

  const storeApi = storeManager.getStoreApi(wixCodeApi, config.compId);
  const consentPolicy = storeManager.getConsentPolicy(wixCodeApi);

  // hereeeee

  if (!albumData.settings.isStoreAlbum && environment.isViewer) {
    return {
      pageReady: async () => {
        setProps({ hidden: true });
      },
    };
  }

  const { type } = config;
  // TODO: Fedops
  // const fedopsLogger = new CartFedopsLogger(context);
  // fedopsLogger.reportAppLoadStarted();

  const albumColors = albumData?.settings.colors;

  const _publicData = { APP: undefined, COMPONENT: { cartIconText: 'Cart' } };
  const _styleParams = {
    colors: {
      cartIconColor: { value: albumColors?.pageTexts },
      cartIconFontColor: {
        themeName: { value: albumColors?.pageTexts },
      },
    },
    numbers: { cartIcon: 0 },
    booleans: { ignoreSavedConnectedProviders: false },
    // fonts: {
    //   cartIconFont: {
    //     family: 'proxima-n-w01-reg',
    //     style: { bold: false, italic: false, underline: false },
    //     size: 18,
    //     preset: 'Custom',
    //     editorKey: 'font_8',
    //     fontStyleParam: true,
    //     value:
    //       'font:normal normal normal 18px/22px proxima-n-w01-reg,sans-serif;',
    //   },
    // },
    googleFontsCssUrl: '',
    uploadFontFaces: '',
  };

  appStateManager.registerWidget({
    name: 'cart',
    setProps,
  });

  return {
    pageReady: () => {
      const pushToRight =
        !isAlbumTemplateV8(albumData.settings) &&
        !shouldAllowSelections(
          albumData.settings,
          wixCodeApi.site.currentPage.name,
        );

      const currentPageName = wixCodeApi.site.currentPage.name;
      storeManager.initCartApiIfNeeded(isSSR);
      if (!isSSR) {
        storeManager.initTranslations(setProps);
      }
      storeManager.addStoreWidget({ setProps, compId, type: 'CART' });
      try {
        const albumsSettings = {
          albumId: albumData.settings.id,
          ...albumData.settings,
        };
        storeManager
          .initStoreWixCodeApi(
            albumsSettings,
            _styleParams,
            ALBUMS.ALBUMS_APP_DEF_ID,
          )
          .then(({ connectedProviders, additionalProviderParams }: any) => {
            const initalProps = {
              styleParams: _styleParams,
              publicData: _publicData,
              storeApi,
              setActiveStoreWidget: () =>
                storeManager.changeActiveStoreWidget({
                  compId,
                  setProps,
                  type,
                }),
              consentPolicy,
              tryToReportAppLoaded: () => {},
              ...storeManager.getInitalProps(
                { compId, setProps, type, consentPolicy },
                connectedProviders,
                ALBUMS.ALBUMS_APP_DEF_ID,
                additionalProviderParams,
              ),
              hidden: !albumData.settings.isStoreAlbum,
              isPreview: environment.isPreview,
              isEditor: environment.isEditor,
              pushToRight,
              currentPageName,
              loggedIn: appStateManager.state.user.loggedIn,
              appInstance: instance,
              isAlbumV8: isAlbumTemplateV8(albumData.settings),
            };
            setProps(initalProps);
          });
      } catch (e: any) {
        console.error(e);
      }
    },
    exports: () => {},
  };
};

export default createController;
